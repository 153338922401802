import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../runtime-context';
import {
  getTagLayout,
  getTagVerticalSpacing,
  getTagHorizontalSpacing,
} from '../../../selectors/app-settings-selectors.js';
import { isTagLayoutButton } from '@wix/communities-blog-client-common/dist/src/constants/tag-constants';
import { getAppConfig } from '../../../store/app-config/app-config-selectors';
import { getTagUrl } from '../../../services/get-tag-url';
import styles from './tag.scss';

const Tag = ({
  tagUrl,
  label,
  tagLayout,
  tagVerticalSpacing,
  tagHorizontalSpacing,
  publicationCount,
  showPostCount,
  tagClicked,
  id,
  postId,
}) => {
  const tagLayoutButton = isTagLayoutButton(tagLayout);

  const tagStyles = {
    marginLeft: tagHorizontalSpacing,
    marginTop: tagVerticalSpacing,
  };

  const textTagClasses = [styles.blogPostTextTagsFont, styles.blogPostTextTagsColor];
  const buttonTagClasses = [
    styles.tagButton,
    styles.blogPostButtonTagsFont,
    styles.blogPostButtonTagsColor,
    styles.blogPostButtonTagsBorder,
    styles.blogPostButtonTagsBackground,
    styles.blogPostButtonTagsBorderColor,
    styles.blogPostButtonTagsPadding,
  ];

  const tagClassName = classNames(styles.tag, ...(tagLayoutButton ? buttonTagClasses : textTagClasses));

  return (
    <li className={styles.tag} style={tagStyles}>
      <a onClick={() => tagClicked({ label, id, postId })} href={tagUrl} className={tagClassName}>
        {label} {showPostCount && `(${publicationCount})`}
      </a>
    </li>
  );
};

Tag.propTypes = {
  tagUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  postId: PropTypes.string,
  tagLayout: PropTypes.number.isRequired,
  tagVerticalSpacing: PropTypes.number.isRequired,
  tagHorizontalSpacing: PropTypes.number.isRequired,
  publicationCount: PropTypes.number,
  showPostCount: PropTypes.bool,
  tagClicked: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, { publicationCount, showPostCount, slug }, actions) => {
  const { sectionUrl } = getAppConfig(state);

  return {
    tagLayout: getTagLayout(state),
    tagVerticalSpacing: getTagVerticalSpacing(state),
    tagHorizontalSpacing: getTagHorizontalSpacing(state),
    tagUrl: getTagUrl(sectionUrl, slug),
    showPostCount: showPostCount && !!publicationCount,
    tagClicked: actions.tagClicked,
  };
};

export default connect(mapRuntimeToProps)(Tag);
