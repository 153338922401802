import React from 'react';
import PropTypes from 'prop-types';
import { getTags } from '@wix/communities-blog-client-common/dist/src/store/tags/tags-selectors';
import { connect } from '../../../common/components/runtime-context';
import Tags from '../../../common/components/tags';

const TagCloud = ({ tags }) => <Tags tags={tags} />;

TagCloud.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
};

const mapRuntimeToProps = state => ({
  tags: getTags(state),
});

export default connect(mapRuntimeToProps)(TagCloud);
